/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* Estilo tabla CardsOnSell */


.commander {
  position: absolute;
  right: -30px;
  z-index: -2;
  transform: scale(0.5);
}

#clases{  margin-right: 20px;}
  #image-uploads {
    width: 100%;
    height: 100%;
    border: 1px solid #fe9e0d;
    padding: 10px;
    margin-bottom: 10px; 
    border-radius: 5px;
    
  }
  
#image-upload {
  width: 100%;
  height: 100%;
  border: 1px solid #fe9e0d;
  padding: 10px;
  margin-bottom: 10px; 
  border-radius: 5px;
  
}
#image-upload:hover {
  border: 1px solid #1976d2;
}

#sellcard {
  padding: 0.5rem 0.9rem;
  background-color: #fe9e0d;
  border: none;
  border-radius: 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: 0.2s;
  margin-inline: 10px;
  
  
}


#bidcard {
  padding: 0.5rem 0.9rem;
  background-color: #fe9e0d;
  border: none;
  border-radius: 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: 0.2s;
  margin-inline: 10px;
  
  
}

.card-images-modal {
  display: flex;
  gap: 1rem;
}

.card-images {
  width: 300px;
}

.conversations-section-header {
  margin: 1rem;
}

.conversation-card-box {
  display:flex;
  cursor: pointer;
}

.conversation-card-box:hover {
  background-color: hsla(240, 63%, 61%, 0.235);
}

.card-content {
  width: 100%
}

.conversation-user-info{
  display: flex;
  align-items: center;
  gap: 1rem;
}

.header-card-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.inbox-wrapper {
  display: flex;
  margin: 5rem;
  gap: 2rem;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  grid-gap: 10px;
  justify-items: center;
}

.card-grid-home {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  justify-items: center;
}

.conversations-section{
  width: 25vw;
  max-width: 25vw;
  height: 80vh;
  max-height: 80vh;
  overflow-y: auto;
  border: 1px solid hsla(0, 0%, 0%, 0.3);
  border-radius: 2px;
  z-index: 0;
  background-color: white;;
}

.messages-section {
  width: 60vw;
  max-width: 60vw;
  height: 80vh;
  max-height: 80vh;
  border: 1px solid hsla(0, 0%, 0%, 0.3);
  border-radius: 2px;
  z-index: 1;
  padding: 2rem;
  background-color: white;
}

.message-box-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding-bottom: 1rem;
}

.messages-container {
  height: 80%;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  overflow-y: auto;
  gap: 0.3rem;
}

.user-message {
  background-color:hsla(36, 99%, 52%, 0.3);
  width: fit-content;
  max-width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px transparent;
  border-radius: 4px;
  margin-right: 1rem;
}

.other-user-message {
  background-color: hsla(0, 0%, 0%, 0.3);
  width: fit-content;
  max-width: 100%;
  height: 2.5rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  border: solid 1px transparent;
  border-radius: 4px;
}

.message-date {
  font-size: 0.8rem;
  padding: 0 0.2rem 0 0.4rem;
  color: grey;
}

.tick-icon {
  width: 10px;
  font-size: 5px;
}

.message {
  font-size: 1rem;
}

.messages-input-container {
  width: 100%;
  height: 3rem;
  margin-top: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
}

.messages-input {
  width: 95%;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0 1rem;
}


.grid-header {
  font-weight: bold;
}

.text-area {
  width: 100%;
  padding: 10px;
}

.buynow-button{
  padding: 0px;  
  background-color: #fe9e0d;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: 0.2s;
  margin-inline: 10px;
  height: 28px;
}
.buynow-button:hover{  
  background-color: rgb(234, 234, 234);
}

.buy-button {
  padding: 0px;
  background-color: #fe9e0d;
  border: none;
  border-radius: 37px;
  cursor: pointer;
  transition: 0.2s;
  margin-inline: 10px;
  height: 28px;
  width: 28px;
}

.buy-button:hover {
  background-color: rgb(234, 234, 234);
}

.card-detail-symbol-image {
  width: 20px;
  height: 20px;
}

.column-names {
  position: relative;
}

.column-names-icon{
  position: absolute;
}

.column-names-icon:hover{
  background-color: #ccc;
  border-radius: 4px;
}

.grid-content {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: max-content;
  text-align: center;
}

.grid-content-colspan {
  grid-column: span 2;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  width: max-content;
  text-align: center;
}

.grid-content-smaller {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;
  font-size: 0.8rem;
  width: max-content;
}

.buynow-symbol-image {
  width: 28px;
  height: 28px;
}
.buy-symbol-image{
  width: 20px;
  height: 20px;
  margin-top: 4.7px;
}

.cards-list {
  display: flex;
  gap: 1rem;
}

.cards-list-filter {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

#cards-list-button {
  margin: 1rem auto;
}




.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ajusta la altura según tus necesidades */
}

.box-container {
  width: 400px; /* Ajusta el ancho según tus necesidades */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.registration-form-box {
  margin-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-form-box {
  margin: 6rem auto 0 auto;
  /* max-width: 62rem;
  background-color: red; */
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
}

.box-item {
  margin-bottom: 10px;
  text-align: center;
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Reem Kufi", sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: 0 0 0 #4c4c4c; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:#c3c1c18d; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c3c1c1; 
}

img {
  max-width: 100%;
  height: auto;
  
}

.nav-logo-container img {
  display:block;
  padding: 0.1rem 0;
}

.App {
  min-height: 100vh;
  width: 100vw;
  /* max-width: 1900px; */
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow: auto !important;
  /* overflow-x: hidden !important; */
}

body {
  background-color: #f6f6f6;
}

.header {
position: sticky;
width: 100%;
top: 0;
overflow: visible;
padding-right: 2rem;
padding-left: 4rem;
z-index: 2;
height: 60px;
background-color: #f6f6f6;
border-bottom: 1px solid black;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* min-height: 90px;
  padding: .3rem 0; */
}



#details {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  max-width: 50rem;
  width: 80%;
  margin-bottom: 2rem;
}

#details-child {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 5px 5px;
  justify-content: flex-start;
  width: 250px;
  gap: 2.4rem;
}

#credentials {
  margin-top: 2rem;
  margin-bottom: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  max-width: 50rem;
  width: 80%;
}

#credentials-child {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 5px 5px;
  justify-content: flex-start;
  width: 250px;
  gap: 2rem;
}

.search-results {
  position: absolute;
  top: 51px;
  z-index: 1;
  max-height: 400px;
  width: 255pX;
  -webkit-box-shadow: -16px 16px 15px -18px rgba(107,107,107,1);
  -moz-box-shadow: -16px 16px 15px -18px rgba(107,107,107,1);
  box-shadow: -16px 16px 15px -18px rgba(107,107,107,1);
  border-radius: 2%;
  background-color: white;
}

.search-results a {
  display: block;
}

.cart-box {
  max-height: 75vh;
  width: 350px;
  padding-bottom: 8px;
}

.card-on-cart-box {
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 75px;
  /* justify-content: space-around; */
}

#cart-text {
  width: 80%;
}

#cart-collection {
  font-size: 0.8rem;
}

.cart-button-wrapper {
  padding-bottom: 10px;
}

#cart-button {
  margin: 0 auto;
}


.search-result-item {
  height: 30px;
  font-size: 1rem;
  font-weight: 100;
  padding-top: 3px;
  margin: 3px;
  width: 235px;
}

.search-result-item:hover {
  /* background-color: #c3c1c1; */
  background-color: #fe9e0d69;
  border-radius: 5px;
}

.search-result-background {
  background-color: white;
  cursor: pointer;
}

.message-alert {
  background-color: #fe9e0d;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alertDate-card-box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem; 
}

.results-box {
  max-height: 369px;
  overflow-y: scroll;
  font-size: 1rem;
}

#search-button {
  margin: 0 auto;
}

#search-button {
  font-weight: 100;
  width: fit-content;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 0.7rem;
  color: blue;

}

#search-button:hover {
  font-weight: 500;
}

.box {
  background-color: transparent;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
}


.registration-form-box input {
  box-sizing: border-box;
  padding: 25px 10px;
}

.form-box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}

.details-error-message {
  padding-left: 0;
  position: absolute;
  margin-top: 55px;
}

.credentials-error-message {
  padding-left: 0;
  position: absolute;
  margin-top: 70px;
}

.error-messages {
  color: red;
  font-size: 0.8rem;
}

.profile-error-message {
  position: relative;
  margin-bottom: 1rem;
}

.card-detail-box {
  display: flex;
  flex-direction: column;
  margin: 5rem auto;
  align-items: flex-start; 
  gap: 20px;
  max-width: 1000px;
  justify-content: center;
}

.card-detail-image {
  /*width: 300px;
  height: 420px;
  margin-right: 16px; */
  width: 300px;
  height: auto;
}

.card-detail-image-container{
  display: flex;
  flex: 0 0 auto;
}

.card-detail-and-image {
  display: flex;
}

.card-detail-counter{
  display: flex;
  justify-content: center;;
}

.card-detail-content {
  /*display: flex;
  flex-direction: column;
  justify-content: center;*/
  flex: 1 1 auto;
  
}

.card-detail-symbol-image {
  width: 15px;
  height: 15px;
}


.rarity-common {
  background-color: #000;
  color: #fff;
}

.rarity-uncommon {
  background-color: #868686;
  color: #ffffff;
}

.rarity-rare {
  background-color: gold;
  color: #000000;
}

.rarity-mythic {
  background-color: orange;
  color: #000000;
}

.status-new{  
  background-color: rgb(160, 250, 0) 
}
.status-almost_new{
  background-color: rgba(72, 201, 7, 0.404) 
}
.status-excellent{
  background-color: rgb(0, 250, 146) 
}
.status-good{
  background-color: rgb(196, 250, 0) 
}
.status-lightly_played{
  background-color: rgb(253, 193, 90) 
}
.status-played{
  background-color: rgb(250, 162, 0) 
}
.status-poor{
  background-color: rgb(250, 0, 0) 
}


.card-table {
  width: 100%;
  border-collapse: collapse;
}

.card-table th, .card-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.card-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.card-table tr:hover {
  background-color: #f5f5f5;
}



.navbar-menu-container {
  display: none;
}

.navbar-links-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.navbar-links-container a {
  margin-right: 2rem;
  text-decoration: none;
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
}
.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.avatar-image {
  max-height: 200px;
  border:  2px solid rgba(118, 118, 118, 0.3);
  border-radius: 4px;
  width: auto;
}

.avatar-navbar-image {
  max-height: 50px;
  border-radius: 50%;
}

#search-menu {
  margin-top: 1.4rem;
  width: 100%
}

#basic-menu {
  margin-top: 1.4rem;
}

.profile-anchor {
  color: inherit;
  text-decoration: none;
  width: 100%;
  margin: 0 4px;
  text-align: center;
}

.profile-anchor:hover {
  background-color: hsla(36, 99%, 52%, 0.2);
}

.logged-user-menu {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 150px;
  /* justify-content: center;
  align-items: center; */
}

.login-form-box {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.login-form-box p {
  padding: 0.8rem;
}

#login-form-box-button {
  width: fit-content;
  padding: 0.8rem;
  margin-bottom: 0.5rem;
}

#logged-form-box-button {
  width: fit-content;
  padding: 0.8rem;
}

#login-form-box-button[disabled] {
background-color: #6a6a6a;
}

#login-form-box-button:hover[disabled] {
  background-color: #6a6a6a;
  cursor: default;
}

#login-form-box-button:hover:not[disabled] {
  background-color: #e48f0f;
}

#search-bar{ 
  position: 'relative';
  z-index: 1;
  background-color: hsla(0, 0%, 42%, 0.10);
}

.navbar-icon {
  font-size: 1.5rem;
  margin-right: 2rem;
  cursor: pointer;
}

.navbar-avatar-container {
  display: flex;
  align-items: center;
}

.navbar-cart-icon{
  font-size: 1.5rem;
  transform: translatey(2px);
}

.primary-button:hover {
  background-color: rgb(234, 234, 234);
}
.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 1rem;
  padding-left: 4rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -170px;
  right: -170px;
  z-index: -2;
  max-width: 700px;
  line-height: 0;
}


.home-image-section {
  max-width: 600px;
  flex: 1;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 600px
}
.primary-heading {
  font-size: clamp(2rem, 5vw, 4rem);
  color: #4c4c4c;
  line-height: 5rem;
  max-width: 600px;
}
.primary-text {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.small-text {
  font-size: clamp(1rem, 3vw, 1rem);
  max-width: 500px;
  color: #6a6a6a;
  margin: 1.5rem 0rem;
}
.force-flex{
  display: flex;
}
.secondary-button {
  padding: 1rem 2.5rem;
  background-color: #fe9e0d;
  outline: none;
  border: 0.15rem solid transparent;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: white;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #e48f0f;
}

.secondary-button:focus {
  border: 0.15rem solid #484848
}
.about-section-container {
  margin-top: 20rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  margin-right:400px;
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.primary-subheading {
  font-weight: 700;
  color: #fe9e0d;
  font-size: 1.15rem;
}
.about-buttons-container {
  margin-top: 2rem;
  display: flex;
}

.sell-buttons-container {
  display: flex;
  justify-content: center;
}

.sell-button {
  padding: 0.5rem 0.9rem;
  background-color: #fe9e0d;
  border: none;
  border-radius: 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: 0.2s;
  margin-inline: 10px;
}

.button-box-message-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-send-message-button {
  padding: 0.5rem 0.9rem;
  background-color: #fe9e0d;
  border: none;
  border-radius: 1rem;
  font-size: 1.1rem;
  cursor: pointer;
  transition: 0.2s;
  /* position: absolute;
  left: 50%;
  bottom: -30%;
  transform: translate(-50%, 0); */
}

.sell-button:hover {
  background-color: rgb(234, 234, 234);
}

.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #484848;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 6rem 0rem;
}
.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 3rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
}
.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.footer {
  /* padding-top: 10rem 0; */
  margin-top: 12rem;
  height: 10rem;
}

.footer-wrapper {
  /* padding-top: 50px; */
  padding: 50px 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-left: 4rem; */
  gap: 3rem;
  border-top: 1px solid black;
}
.footer-logo-container {
  max-width: 110px;
}

.footer-icons {
  margin-top: 10px; 
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.footer-icons a {
  display: inline-block;
  width: fit-content;
}


.footer-icons svg {
  font-size: 1.5rem;
  /* margin-right: 1.25rem; */
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 2rem auto;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 15rem;
}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;
}
.work-section-bottom {
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.randomcard-section-wrapper {
  margin-top: 1rem;
}
.randomcard-section-main {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  align-items: flex-start;
}
.randomcard-section-info {
  width: 200px;
  min-height: 310px;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
}
.randomcard-section-info img {
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 1rem;
}
.randomcard-section-info h2 {
  margin: 0rem 1rem;
  font-size: 1rem;
}
.randomcard-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}
.card-image {
  width: 100%; 
  height: auto; 
  max-width: 200px; 
  max-height: 300px; 
}
@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 600px;
  }
}
@media (max-width: 1015px) {
  .nav-logo-container {
    max-width: 140px;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .about-section-container {
    flex-direction: column;
  }
  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
  }
  .primary-heading {
    text-align: center;
    max-width: 90%;
  }
  .primary-text {
    text-align: center;
    max-width: 80%;
  }
  .home-text-section,
  .about-section-text-container {
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
  }
  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  .randomcard-section-wrapper {
    margin-top: 1rem !important;
  }
  .randomcard-section-main {
    margin-top: 1rem !important;
  }
  .card-image {
    max-width: 150px;
    max-height: 225px;
  }
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }
  .card-image {
    max-width: 120px;
    max-height: 180px;
  }
}
